import { defineStore } from "pinia";
import RestApiService from "@/api/restapi.service";
import { Pagination } from "@/types/Pagination";
import { Customer, CustomerCollection } from "@/types/Customer";
import { CustomerFeature } from "@/types/Feature";

export const useCustomerStore = defineStore({
  id: "customer",
  state: () => ({
    errors: null,
    loading: false,
    customer: {} as Customer,
    customerList: {} as CustomerCollection,
    features: [] as Array<CustomerFeature>,
  }),
  getters: {},
  actions: {
    async fetchCustomerList(pagination: Pagination) {
      this.loading = true;
      const data = await RestApiService.get("/customers", pagination)
        .then(({ data }) => {
          this.customerList = data;
          return data;
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
        });
      this.loading = false;
      return data;
    },
    async fetchCustomer(id: number) {
      this.loading = true;
      const data = await RestApiService.get(`/customers/${id}`)
        .then(({ data }) => {
          this.customer = data;
          return data;
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
        });
      this.loading = false;
      return data;
    },

    async fetchCustomerFeatures(id: number) {
      this.loading = true;
      const data = await RestApiService.get(`/customer/${id}/features`)
        .then(({ data }) => {
          this.features = data.data;
          return data;
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
        });
      this.loading = false;
      return data;
    },

    async updateCustomerFeatures(id: number, input: Array<CustomerFeature>) {
      this.loading = true;
      const data = await RestApiService.post(`/customer/${id}/features`, {
        features: input,
      });
      this.errors = data["errors"];
      this.loading = false;
      return data;
    },

    async updateCustomer(customer: Customer) {
      this.loading = true;
      const data = await RestApiService.update(
        "/customers",
        customer.id,
        customer
      );
      this.errors = data["errors"];
      this.loading = false;
      return data;
    },
  },
});
